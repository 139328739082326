import { Box, Grid, Paper, Typography } from "@mui/material";
import Carousel from 'react-material-ui-carousel';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        [theme.breakpoints.up('xs')]: {
            height: '200px'
        },
        [theme.breakpoints.up('sm')]: {
            height: '300px'
        },
        [theme.breakpoints.up('md')]: {
            height: '400px'
        },
        [theme.breakpoints.up('lg')]: {
            height: '400px'
        },
        [theme.breakpoints.up('xl')]: {
            height: '400px'
        },
    },
}));
const CarosolView = ({ items }) => {
    const classes = useStyles();
    return (
        <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Carousel
                autoPlay={true}
                animation="slide"
                navButtonsProps={{
                    style: {
                        // backgroundColor: 'transparent',
                        color: '#FFFFFF',
                        boxShadow: 'none',
                    },
                }}
                navButtonsWrapperProps={{
                    style: {
                        bottom: '10px',
                    },
                }}

            >
                {items.map((item, index) => (
                    <img src={item.imgUrl} alt='Images' className={classes.root} style={{
                        width: '90%',
                    }} />
                ))}
            </Carousel>

        </Box >
    );
};

export default CarosolView;