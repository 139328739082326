import { makeStyles } from "@mui/styles";
import { Container, Grid, Typography, Box, Button } from "@mui/material";
import logo from '../resources/Images/dadhwal-logo.png';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
    footer: {
        backgroundColor: '#333',
        padding: "24px 0",
        marginTop: "auto",
        color: 'white',
    },
    logo: {
        width: "300px",
        height: "auto",
    },
});

const Footer = () => {
    const classes = useStyles();
    const navigate =useNavigate();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg" sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                        <Typography variant='h6' sx={{ display: { xs: 'block', md: 'none' } }}>DADHWAL WEIGHING INSTRUMENT REPAIRING WORKS</Typography>
                        <Typography variant='h5'  sx={{ display: { xs: 'none', md: 'block' } }}>DADHWAL WEIGHING INSTRUMENT REPAIRING WORKS</Typography>
                        <Typography sx={{ fontSize: '18px',display: { xs: 'none', md: 'block' }  }}>
                            Registered Address: Vasant Vihar, Joraphatak Road, Dhanbad 826001<br />
                            Factory Address: F74, Industrial Area, Phase 7, Mohali, Punjab 160055
                        </Typography>
                        <Typography sx={{ fontSize: '14px',display: { xs: 'block', md: 'none' }  }}>
                            Registered Address: Vasant Vihar, Joraphatak Road, Dhanbad 826001<br />
                            Factory Address: F74, Industrial Area, Phase 7, Mohali, Punjab 160055
                        </Typography>
                    </Grid>
                    <Grid md={1} />
                    <Grid item xs={12} md={4}>
                    <Typography onClick={()=>navigate('/PrivacyPolicy')} sx={{ fontSize: '14px',display: { xs: 'block', md: 'none' }, cursor:'pointer'  }}>Privacy Policy</Typography>
                    <Typography onClick={()=>navigate('/PrivacyPolicy')} sx={{ fontSize: '18px',display: { xs: 'none', md: 'block' }, cursor:'pointer'  }}>Privacy Policy</Typography>
                    <br/>
                        <Typography sx={{ fontSize: '14px',display: { xs: 'block', md: 'none' }  }}>Get in Touch <br /> Email: info [at] dadhwal [dot] in</Typography>
                        <Typography sx={{ fontSize: '18px',display: { xs: 'none', md: 'block' } }}>Get in Touch <br />Email: info [at] dadhwal [dot] in</Typography>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{ flexGrow: 1, height: '50px', background: '#111111' }}>
                <Typography sx={{ color: 'white', textAlign: 'center', paddingTop: '13px',fontSize: '14px',display: { xs: 'block', md: 'none' } }}>© Dadhwal, Dhanbad | Mohali. All rights reserved.</Typography>
                <Typography sx={{ color: 'white', textAlign: 'center', paddingTop: '10px',fontSize: '18px',display: { xs: 'none', md: 'block' } }}>© Dadhwal, Dhanbad | Mohali. All rights reserved.</Typography>
            </Box>
        </footer>
    );
};

export default Footer;  