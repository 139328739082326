import { Box, Divider, Typography } from "@mui/material";
import CardComponent from "./CardComponent";
import CompanyCard from "./CompanyCard";
import Nav2 from "./Nav2";
import cctv1 from '../resources/Images/cctv-surveillance-1.jpg';
import cctv2 from '../resources/Images/cctv-surveillance-2.jpg';
import cctv3 from '../resources/Images/cctv-surveillance-3.jpg';
import cctv4 from '../resources/Images/cctv-surveillance-4.jpg';
import cctv5 from '../resources/Images/cctv-surveillance-5.jpg';
import cctv6 from '../resources/Images/cctv-surveillance-6.jpg';
import Navbar from "./NavBar";
import Footer from "./Footer";

const items = [
    {
        imgUrl: cctv1,
    },
    {
        imgUrl: cctv2,
    },
    {
        imgUrl: cctv3,
    },
    {
        imgUrl: cctv4,
    },
    {
        imgUrl: cctv5,
    },
    {
        imgUrl: cctv6,
    },
];


export const CCTVText = () => {
    return (
        <Box sx={{ padding: '20px' }}>
            <Typography sx={{ fontSize: '18px' }}>

                <ul>
                    <li>Our IP CCTV cameras solution uses sophisticated sensors, lenses, processing circuitry along with our wired and wireless networking produce and capture what your eye sees.</li>
                    <li>Our all-weather CCTV surveillance system are able to cope with the difficult Indian conditions.</li>
                    <li>They provide clear and detailed images without any blooming or smearing, under constantly changing lighting conditions.</li>
                    <li>IP6X variants, which are extremely robust and resistant, are available.</li>
                    <li>Continuous recording is ensured throughout. in order to guarantee the high availability of the recording, IP-NVRs with high storage and encoder is provided.</li>
                    <li>Apart from its reliability, the we also ensure its high image quality, for the recording takes place in real-time and broadcast quality with high frame rate</li>
                </ul>
                Owing to modular and flexible design, our systems can be kept up-to-date for many years and thus offer a high level of investment protection
            </Typography>
        </Box >
    )
};

const CCTV = () => {
    return (
        <>
        <Navbar />
        
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
            <Nav2 text="CCTV Surveillance System" />
            <CardComponent items={items} />
        </Box >
        <Box sx={{ height: 20 }} />
            <Footer />
        </>
    );
};

export default CCTV;