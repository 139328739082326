import { Box, Divider, Typography } from "@mui/material";
import CardComponent from "./CardComponent";
import CompanyCard from "./CompanyCard";
import Nav2 from "./Nav2";
import wn1 from '../resources/Images/wireless-networking-1.jpg'
import wn2 from '../resources/Images/wireless-networking-2.jpg'
import wn3 from '../resources/Images/wireless-networking-3.jpg'
import Navbar from "./NavBar";
import Footer from "./Footer";

const items = [
    {
        imgUrl: wn1,
    },
    {
        imgUrl: wn2,
    },
    {
        imgUrl: wn3,
    },
];


export const WirelessText = () => {
    return (

        <Box sx={{ padding: '20px' }}>
            <Typography sx={{ fontSize: '18px' }}>

                Our wireless networking provides superior-quality broadband connectivity for security, surveillance and monitoring from the remote locations to centralised control room. Our wireless networking provides clean and reliable connectivity, drastically improving operational efficiency and productivity to meet all wireless connectivity needs.
                <br /><br />
                We offer a complete line of high-performance network access infrastructure which includes Wi-Fi controllers, indoor and outdoor access points, wired Ethernet switches and IoT suite. We also offer RF planning, cloud systems and management software, along with professional services and support.
            </Typography>
        </Box>
    )
};
const Wireless = () => {
    return (
        <>
            <Navbar />
            
            <Divider />
            <Box sx={{ flexGrow: 1 }}>
                <Nav2 text="Wireless Networking" />
                <CardComponent items={items} />
            </Box >
            <Box sx={{ height: 20 }} />
            <Footer />
        </>
    );
};

export default Wireless;