import { Box, Divider } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Automation from './Components/Automation';
import CCTV from './Components/CCTV';
import ClientsPage from './Pages/ClientsPage';
import Footer from './Components/Footer';
import GNSS from './Components/GNSS';
import HomePage from './Pages/HomePage';
import Perimeter from './Components/Perimeter';
import Proximity from './Components/Proximity';
import Weighbridge from './Components/WB';
import Wireless from './Components/Wireless';
import PrivacyPolicy from './Components/PrivacyPolicy';
import ProductPage from './Pages/ProductPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/clients" element={<ClientsPage />} />
        <Route path="/weighbridge" element={<Weighbridge />} />
        <Route path="/Automation" element={<Automation />} />
        <Route path="/Proximity" element={<Proximity />} />
        <Route path="/Perimeter" element={<Perimeter />} />
        <Route path="/GNSS" element={<GNSS />} />
        <Route path="/CCTV" element={<CCTV />} />
        <Route path="/Wireless" element={<Wireless />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/products" element={<ProductPage />} />
      </Routes>
    </div>
  );
}

export default App;
