import { Box, Divider, Typography } from "@mui/material";
import CardComponent from "./CardComponent";
import CompanyCard from "./CompanyCard";
import Nav2 from "./Nav2";
import prox1 from '../resources/Images/proximity-1.jpg'
import prox2 from '../resources/Images/proximity-2.jpg'
import prox3 from '../resources/Images/proximity-3.jpg'
import Navbar from "./NavBar";
import Footer from "./Footer";

const items = [
    {
        imgUrl: prox1,
    },
    {
        imgUrl: prox2,
    },
    {
        imgUrl: prox3,
    },
];


export const ProxiText = () => {
    return (
       
        <Box sx={{ padding: '20px' }}>
            <Typography sx={{ fontSize: '18px', padding: '10px' }}>
                1. SafeInstruments HSD-Sensor radar solution, matching with special vehicle ADAS system and visual system, can achieve forward and backward obstacle avoidance. Integrated with 180° panoramic thermal camera system in front and read, the system can also achieve complete vehicle monitoring.
                <br />
                2 .With configurable range from 5m-30m detection distance, support flexible configuration of width in coverage area, dangerous level setting according to alarm distance and provide audio and visual warning alarms to driver, which effectively avoid or reduce the forward and rear collisions.
                <br />
                3 .It can actively detect the objects in the target area of vehicle which prevent accidents, ensure security and safety, improve operation efficiency and reduce downtime.
                <br />
                4 .The product is featured with high reliability, high temperature resistance and vibration resistance, which can meet the application requirements in bad working environment such as night, dust and heavy rain.
                <br />
                5 .The system has been widely used to India to meet the application of in Mining equipment, Road Cars, Heavy Engineering vehicles, Heavy Earth Moving Machines, Trucks.
                <br />
            </Typography>
        </Box>
    )
};

const Proximity = () => {
    return (
        <>
        <Navbar />
        
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
            <Nav2 text="Proximity Warning Device" />
            <CardComponent items={items} />
            </Box >
            <Box sx={{ height: 20 }} />
            <Footer />
        </>
    );
};

export default Proximity;