import { Box, Divider, Typography } from "@mui/material";
import CardComponent from "./CardComponent";
import CompanyCard from "./CompanyCard";
import Nav2 from "./Nav2";
import peri1 from '../resources/Images/primeter-surveillance-1.jpg'
import peri2 from '../resources/Images/primeter-surveillance-2.jpg'
import peri3 from '../resources/Images/primeter-surveillance-3.jpg'
import peri4 from '../resources/Images/primeter-surveillance-4.jpg'
import peri5 from '../resources/Images/primeter-surveillance-5.jpg'
import peri6 from '../resources/Images/primeter-surveillance-6.jpg'
import Navbar from "./NavBar";
import Footer from "./Footer";

const items = [
    {
        imgUrl: peri1,
    },
    {
        imgUrl: peri2,
    },
    {
        imgUrl: peri3,
    },
    {
        imgUrl: peri4,
    },
    {
        imgUrl: peri5,
    },
    {
        imgUrl: peri6,
    },
];


export const PeriText = () => {
    return (
        <Box sx={{ padding: '20px' }}>
            <Typography sx={{ fontSize: '18px', padding: '10px' }}>
                <ul>
                    <li>Perimeter surveillance radar sensor can be installed to detect any intruder along the boundary line.</li>
                    <li>The sensor transmits the measurements of the detection using wired/wireless network on real time basis to the server in control room.</li>
                    <li>Any intruder above the speed of 1 km/hr. (adjustable) and cross section of 1m2 (adjustable) is detected if found crossing the detection zone of radar which is along the straight path along the boundary line.</li>
                    <li>Video image is integrated with the system and displayed on the single screen along with graphical location of intrusion. Audio-visual warning is integrated with system at the surveillance radar point as well as in the control room. The system has recording and viewing arrangement in the control room.</li>
                    <li>Accurate incident notifications at night and in all weather conditions</li>
                    <li>Ability to filter on distance</li>
                    <li>90 degrees horizontal detection and up to 300 meters</li>
                    <li>Compatible with major VMS suppliers</li>
                    <li>Power over Ethernet Plus (PoE+)</li>
                    <li>Receive all the measurement data of RADAR and convert into meaningful information/report through TCP/IP.</li>
                    <li>Integrated display and storing of intrusion data & video.</li>
                    <li>Audio-visual warning at the site and in the control room.</li>
                </ul>
            </Typography>
        </Box>
    )
};

const Perimeter = () => {
    return (
        <>
        <Navbar />
        
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
            <Nav2 text="Perimeter Surveillance" />
            <CardComponent items={items} />
        </Box >
        <Box sx={{ height: 20 }} />
            <Footer />
        </>
    );
};

export default Perimeter;