import { Box, Divider, Typography } from "@mui/material";
import CardComponent from "./CardComponent";
import CompanyCard from "./CompanyCard";
import Nav2 from "./Nav2";
import wb1 from '../resources/Images/weighbridge1.jpg'
import wb2 from '../resources/Images/weighbridge2.jpg'
import wb3 from '../resources/Images/weighbridge3.jpg'
import Navbar from "./NavBar";
import Footer from "./Footer";

const items = [
    {
        imgUrl: wb1,
    },
    {
        imgUrl: wb2,
    },
    {
        imgUrl: wb3,
    },
];

export const WBText = () => {
    return (
        <Box sx={{ padding: '20px' }}>
            <Typography sx={{ fontSize: '18px' }}>
                DADHWAL WEIGHING INSTRUMENT REPAIRING WORKS are one of the leading manufacturers Weigh Bridges in India.
                <br />
                <br />
                We have Model approval from Department Legal and Meteorology, Food and Supplies, India for manufacturing and conversion of weighbridges.
                <br />
                <br />
                With over 45 years of experience, our weighbridges are highly recognized and appreciated for their high performance, consistent quality and features.
                <br />
                <br />
                Our product range includes
                <br />
                <br />

                <ul>
                    <li>Fully Electronic Weighbridge,</li>
                    <li>Weighing In-Motion,</li>
                    <li>On-Board Weighing,</li>
                    <li>Weighing Automation</li>
                    <li>Weighbridge Accessories</li>
                </ul>
            </Typography>
        </Box>
    )
};

const Weighbridge = () => {
    return (
        <>
            <Navbar />

            <Divider />
            <Box sx={{ flexGrow: 1 }}>
                <Nav2 text="Weighbridge" />
                <CardComponent items={items} />
            </Box >
            <Box sx={{ height: 20 }} />
            <Footer />
        </>

    );
};

export default Weighbridge;