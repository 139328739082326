import { Box, Divider, Typography } from "@mui/material";
import CardComponent from "./CardComponent";
import CompanyCard from "./CompanyCard";
import Nav2 from "./Nav2";
import auto1 from '../resources/Images/automation1.jpg'
import auto2 from '../resources/Images/automation2.jpg'
import auto3 from '../resources/Images/automation3.jpg'
import Navbar from "./NavBar";
import Footer from "./Footer";


const items = [
    {
        imgUrl: auto1,
    },
    {
        imgUrl: auto2,
    },
    {
        imgUrl: auto3,
    },
];

export const AutoText = () => {
    return (
        <Box sx={{ padding: '20px' }}>
            <Typography sx={{ fontSize: '18px' }}>
                <b>DWIspatch®</b> helps the Mine Owner to increase the dispatch weighment by:
                <br />
                <ul>
                    <li>Weighing System and Centralized Application at Central Control Center features to:</li>
                    <ul>
                        <li>Collect all weighment the data</li>
                        <li>Generate Weighment Slip</li>
                        <li>Generate Weighment Photo Challan Weighment Invoice / TripSheet,</li>
                        <li>Generate e-waybill</li>
                    </ul></ul>
                <b>IN A SINGLE CLICK REDUCING THE WEIGHMENT TIME FROM HOURS TO MINUTES.</b>
                <br /><br />
                <ul>
                    <li>Reduce Manual intervention in the dispatch weighment process where no manual entry is allowed into weighbridge Application reducing the weighment time.</li>
                    <li>Automatic System based authentication of vehicle at the entry of Vehicle in Mine Area to maintain traffic flow, restrict entry and exit of unauthorized vehicle in and out of mine.</li>
                    <li>Weighbridge at Mine Site, Entry / Exit Point are integrated with RFID, Boom Barrier and CCTv for access control and capture images of passing Equipment improve transparency.</li>
                    <li>Loading and Unloading point verification with CCTv and Boom-barrier to with restrict unauthorized access.</li>
                </ul>
                <br /><br />
                <b>WeightPRO® helps the Mine Owner to increase the Mine production with existing resources</b>
                <br />
                Integrated with GPS for real time tracking of vehicles along with weight, route information, location and other vehicle information.
                <ul>
                    <li>Fully automatic and robust Weighing System to complete production weighment in seconds.</li>
                    <li>OITDS and Centralized Application at Central Control Center features to enhance machine utilization which provides:</li>
                    <ul>
                        <li>No dependency on external service providers for vehicle tracking</li>
                        <li>Real time location of mining equipment provides dynamic control to machinery (Dumper-Shovel movement)</li>
                        <li>Centralized control to fleet management system.</li>
                        <li>Provides of complete network coverage of mine with scalability.</li>
                        <li>Real time communication with the operator improving safety and prevent breakdowns.</li>
                        <li>Flexible and efficient network deployment to adjust with changing mining conditions.</li>
                    </ul>
                    <li>Provision of real time communication with machine operator</li>
                    <li>All weather – Real time CCTv footage of strategic locations in mines with 24×7 recording and monitoring facility</li>
                    <li>Improves mine safety.</li>
                    <li>Real time surveillance with provision of virtual fencing for intrusion detection without using hard fence.</li>
                    <li>Real time information relating to daily production of ore, available stock, machine utilization.</li>
                </ul>
            </Typography>
        </Box >
    )
};

const Automation = () => {
    return (
        <>
            <Navbar />
            
            <Divider />
            <Box sx={{ flexGrow: 1 }}>
                <Nav2 text="Automation" />
                <CardComponent items={items} />
            </Box >
            <Box sx={{ height: 20 }} />
            <Footer />
        </>
    );
};

export default Automation;