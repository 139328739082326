// Updated MediaControlCard component
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid, Divider, List, ListItem, ListItemText, ListItemIcon, ListSubheader } from '@mui/material';
import renderIcon from './FeaturesIcon';

export default function ProductCard({ index,cardData }) {
    console.log(index,cardData);
    const theme = useTheme();

    return (
        <Grid container justifyContent="center" sx={{ padding: 5 }}>
            <Grid item xs={12} sm={12}>
                <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: '100%' }}>
                    {index%2==0?
                    <>
                    <CardMedia
                        component="img"
                        sx={{ width: { xs: '100%', md: 300 }, objectFit: 'cover', alignSelf: 'center', marginRight: { xs: 0, md: 2 } }}
                        image={cardData.img}
                        alt={cardData.title+" Image"}
                    />
                    <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' }, marginRight: "20px" }} />
                    </>
                    :<></>}
                    <CardContent sx={{ flex: 1 }}>
                        <Typography variant="h5" gutterBottom>
                            {cardData.title}
                        </Typography>
                        <Typography variant="body1">
                            {cardData.description}
                        </Typography>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper', marginTop: '20px' }}
                            subheader={<ListSubheader sx={{ fontSize: 16 }}>Key Features:</ListSubheader>}
                        >
                            {cardData.features.map((feature, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        {renderIcon(feature.icon)}
                                    </ListItemIcon>
                                    <ListItemText primary={feature.text} />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                    {index%2==1?
                    <>
                    <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' }, marginLeft: "20px" }} />
                    <CardMedia
                        component="img"
                        sx={{ width: { xs: '100%', md: 300 }, objectFit: 'cover', alignSelf: 'center', marginLeft: { xs: 0, md: 2 } }}
                        image={cardData.img}
                        alt={cardData.title+" Image"}
                    />

                    </>
                    :<></>}
                </Card>
            </Grid>
        </Grid>
    );
}
