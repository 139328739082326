import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import logo from '../resources/Images/dadhwal-logo.png';
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Box, IconButton } from "@mui/material";
import { Menu as MenuIcon } from '@mui/icons-material';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    color: 'black',
    boxShadow: 'none',
}));
const Navbar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if (anchorEl === null) {
            setAnchorEl(event.currentTarget);
        }
        else {
            setAnchorEl(null);
        }

    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
    }, [navigate])

    const handleScroll = (e) => {
        e.preventDefault();
        handleClosesm();
        window.scrollTo({
            top: 5000,
            left: 0,
            behavior: "instant"
        });
    };

    const location = useLocation();
    const handleNav = (nav) => {
        if (location.pathname === nav) {
            handleClose();
            handleClosesm();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant"
            });
        }
        else {
            handleClose();
            handleClosesm();
            navigate(nav);
        }
    };

    const [anchorElsm, setAnchorElsm] = useState(null);

    const handleClicksm = (event) => {

        setAnchorElsm(event.currentTarget);
    };

    const handleClosesm = () => {
        setAnchorElsm(null);
    };

    return (
        <>
            <StyledAppBar position="fixed">
                <Toolbar>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <img src={logo} alt='logo' style={{
                            paddingTop: '5px',
                            width: '280px',
                            height:'60px',
                        }}
                            onClick={() => navigate("/")} />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <img src={logo} alt='logo' style={{
                            paddingTop: '5px',
                            width: '400px',
                            height:'80px',
                        }}
                            onClick={() => navigate("/")} /></Box>

                    <div style={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <IconButton color="inherit" onClick={handleClicksm}>
                            <MenuIcon />
                        </IconButton>
                        <Menu anchorEl={anchorElsm} open={Boolean(anchorElsm)} onClose={handleClosesm}>
                            <MenuItem onClick={() => handleNav("/")}>Home</MenuItem>
                            <MenuItem onClick={() => handleNav("/clients")}>Our Clients</MenuItem>
                            <MenuItem onClick={() => handleNav('/weighbridge')}>Weighbridge</MenuItem>
                            <MenuItem onClick={() => handleNav('/Automation')}>Automation</MenuItem>
                            <MenuItem onClick={() => handleNav('/Proximity')}>Proximity Warning Device</MenuItem>
                            <MenuItem onClick={() => handleNav('/Perimeter')}>Perimeter Surveillance</MenuItem>
                            <MenuItem onClick={() => handleNav('/GNSS')}>GNSS Based Fleet Management System</MenuItem>
                            <MenuItem onClick={() => handleNav('/CCTV')}>CCTV Surveillance System</MenuItem>
                            <MenuItem onClick={() => handleNav('/Wireless')}>Wireless Networking</MenuItem>
                            <MenuItem onClick={handleScroll}>Contact Us</MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Stack direction="row" spacing={2}>
                            <Button color="inherit" onClick={() => handleNav("/")} >Home</Button>
                            <Button color="inherit" onClick={() => handleNav("/products")}>Products</Button>
                            <Button
                                id="basic-button"
                                onClick={handleClick}
                                sx={{ color: 'black' }}
                            >
                                Solutions
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => handleNav('/weighbridge')}>Weighbridge</MenuItem>
                                <MenuItem onClick={() => handleNav('/Automation')}>Automation</MenuItem>
                                <MenuItem onClick={() => handleNav('/Proximity')}>Proximity Warning Device</MenuItem>
                                <MenuItem onClick={() => handleNav('/Perimeter')}>Perimeter Surveillance</MenuItem>
                                <MenuItem onClick={() => handleNav('/GNSS')}>GNSS Based Fleet Management System</MenuItem>
                                <MenuItem onClick={() => handleNav('/CCTV')}>CCTV Surveillance System</MenuItem>
                                <MenuItem onClick={() => handleNav('/Wireless')}>Wireless Networking</MenuItem>

                            </Menu>
                            
                            <Button color="inherit" onClick={() => handleNav("/clients")}>Our Clients</Button>
                            <Button color="inherit" onClick={handleScroll}>Contact Us</Button>
                        </Stack>
                    </Box>
                </Toolbar>
            </StyledAppBar >
            <Box sx={{ display: { xs: 'block', md: 'none', height: 80 } }} />
            <Box sx={{ display: { xs: 'none', md: 'block', height: 100 } }} />
        </>
    );
};

export default Navbar;