import { Grid, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import bccl from '../resources/Images/bccl.png';
import ecl from '../resources/Images/ecl.png';
import necl from '../resources/Images/necl.png';
import nmdc from '../resources/Images/nmdc.png';
import cimfr from '../resources/Images/cimfr.png';
import sail from '../resources/Images/sail.png';
import seci from '../resources/Images/seci.png';
import { useNavigate } from "react-router-dom";
const logos = [bccl, ecl, necl, nmdc, cimfr, sail, seci];


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    card2: {
        padding: 16,
        margin: 16,
        "@media (min-width: 600px)": {
            marginRight: 16,
            marginBottom: 0,
        },
        backgroundColor: "#abb8c3",
    },
    card1: {
        padding: 16,
        margin: 16,
        "@media (min-width: 600px)": {
            flexGrow: 1,
        },
    },
});

const LogoContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
}));

const LogoImage = styled('img')({
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
});
const CompanyCard = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8.5}>
                    <Card className={classes.card1}>
                        <LogoContainer container spacing={3}>
                            {logos.map((logo) => (
                                <Grid item xs={12} sm={6} md={4} key={logo} sx={{ textAlign: 'center' }}>
                                    <LogoImage src={logo} alt="company logo" />
                                </Grid>
                            ))}
                        </LogoContainer>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3.5}>
                    <Card className={classes.card2}>
                        <CardContent>
                            <Typography variant='h5'>
                                SOLUTIONS
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/automation')}>
                                Automation System in Mines
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/weighbridge')}>
                                Weighbridge Installation and Software
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/Wireless')}>
                                Networking using Fiber / RF
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/CCTV')}>
                                CCTV Surveillance
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/Proximity')}>
                                Proximity Warning Device
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/Perimeter')}>
                                Perimeter Surveillance
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/GNSS')}>
                                GNSS based Fleet Management System
                            </Typography>
                            <br />

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default CompanyCard;