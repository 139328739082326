import { Box, Divider } from "@mui/material";
import CompanyCard from "../Components/CompanyCard";
import Footer from "../Components/Footer";
import Nav2 from "../Components/Nav2";
import Navbar from "../Components/NavBar";

const ClientsPage = () => {
    return (
        <>
        <Navbar />
        
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
            <Nav2 text="Our Clients" />
            <CompanyCard />
        </Box >
        <Box sx={{ height: 20 }} />
            <Footer />
        </>
    );
};

export default ClientsPage;