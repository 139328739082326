import React from "react";
import { Typography, Box } from "@mui/material";

const Nav2 = ({text}) => {
    return (
        <Box sx={{ display: "flex", height: "70px", backgroundColor: '#004E7A', color: 'white', alignSelf: 'center', paddingLeft: '10px' }}>
            <Typography variant='h5' sx={{ alignSelf: 'center' }}>
                {text}
            </Typography>
        </Box>

    );
};

export default Nav2;