import { Box, Divider, Typography } from "@mui/material";
import CardComponent from "./CardComponent";
import CompanyCard from "./CompanyCard";
import Nav2 from "./Nav2";
import gnss1 from '../resources/Images/gnss-fleet-management-system-india1.jpg'
import gnss2 from '../resources/Images/gnss-fleet-management-system-india2.jpg'
import Navbar from "./NavBar";
import Footer from "./Footer";

const items = [
    {
        imgUrl: gnss1,
    },
    {
        imgUrl: gnss2,
    },
];


export const GNSSText = () => {
    return (
        <Box sx={{ padding: '20px' }}>
            <Typography sx={{ fontSize: '18px' }}>
                Our GNSS base fleet management system for allows user to maximize the production and efficiency, while increasing safety, security and control over complete fleet.

                Using state-of-the-art field our own communication hardware and, along with best connectivity, our fleet management system provides network independent industry-proven fleet optimization solution.

                Our DWI-FMS features include:
                <ul>
                    <li>Online real time monitoring Interface.</li>
                    <li>Proximity awareness.</li>
                    <li>Accurate equipment positioning and tracking with accuracy of (10cm),</li>
                    <li>Equipment health monitoring and maintenance.</li>
                    <li>Real time Payload reporting.</li>
                    <li>Advance trip optimisation.</li>
                    <li>Fuel and Oil Management.</li>
                    <li>Ability to integrate with available sensors such as tire-pressure, fuel and Oil monitoring, camera.</li>
                </ul>
            </Typography>
        </Box >
    )
};
const GNSS = () => {
    return (
        <>
        <Navbar />
        
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
            <Nav2 text="GNSS Based Fleet Management System" />
            <CardComponent items={items} />
        </Box >
        <Box sx={{ height: 20 }} />
            <Footer />
        </>
    );
};

export default GNSS;