import rfid from '../../resources/rfid.png';
import vts from '../../resources/vts-device.png';
import sw1 from '../../resources/switch.png';
import desktopRfid from '../../resources/desktop-rfid.png';
import iot from '../../resources/iot.png';
import rack from '../../resources/rack.png';
import handrfid from '../../resources/hand-rfid.png';
import signal from '../../resources/signal.png';
import centering from '../../resources/centring.png';
import boom from '../../resources/boom.png';
import rfidtag from '../../resources/rfid-tag.png';

export const cardContent = [
  {
    title: "RFID Outdoor Reader",
    img: rfid,
    description: "Built for demanding outdoor environments, our RFID Outdoor Reader offers exceptional performance and reliability. With a reading/write range of up to 15 meters, it seamlessly reads and writes tags in diverse applications like access control, traffic management, waste management, manufacturing automation, and weighbridge automation.",
    features: [
      {
        icon: "security",
        text: "Unmatched Durability: BIS Certified and CE Compliant, the reader boasts an IP68 rating, making it dustproof and waterproof for long-lasting operation even in harsh weather conditions."
      },
      {
        icon: "thermometer",
        text: "Extreme Environment Performance: Engineered to withstand extreme temperatures, intense lighting, and electromagnetic interference, this reader ensures consistent performance even in challenging environments."
      },
      {
        icon: "wifi",
        text: "Advanced Anti-Jamming: Equipped with advanced anti-jamming features, it eliminates interference and ensures accurate tag reading even in congested RFID environments."
      },
      {
        icon: "installation",
        text: "Flexible Mounting Options: Mount the reader on walls, poles, roofs, or towers for optimal positioning and coverage."
      },
      {
        icon: "code",
        text: "Simplified Integration: An intuitive SDK enables effortless integration with existing systems, streamlining your workflow."
      }
    ]
  },
  {
    title: "RFID Proximity Reader/Writer",
    img: desktopRfid,
    description: "Manage your access control, asset tracking, or other RFID needs with ease using our versatile and secure RFID Proximity Reader/Writer. Featuring a built-in antenna and multi-protocol support, it offers reliable performance in various applications.",
    features: [
      {
        icon: "security",
        text: "Durable Design: BIS Certified and CE Compliant, the reader boasts an IP54/IP68 rating, protecting it from dust, water, and harsh environments. Choose the IP rating that best suits your needs."
      },
      {
        icon: "installation",
        text: "Flexibility: The built-in antenna eliminates the need for external antennas, simplifying installation and offering polarization-free reading."
      },
      {
        icon: "connectivity",
        text: "Multiple Connectivity Options: Connect seamlessly to your system via USB, Serial, RS232, or RJ45 for easy integration."
      },
      {
        icon: "code",
        text: "Powerful SDK: Customize and integrate the reader effortlessly with your existing systems using the provided SDK."
      }
    ]
  },
  {
    title: "RFID Tags",
    img: rfidtag,
    description: "Boost your asset tracking, inventory management, and identification processes with our robust EPC Class 1 Gen 2 RFID Tags. These non-reusable sticker tags offer unmatched performance and versatility across diverse applications.",
    features: [
      {
        icon: "shield",
        text: "Global Standards Compliance: Adhere to EPC CLASS1 Gen 2 / ISO 18000-6C/ ISO18000-6B standards for universal compatibility."
      },
      {
        icon: "id-card",
        text: "Unique Identification: Each tag comes with a fixed and non-editable factory programmed global unique ID, ensuring accurate and reliable tracking."
      },
      {
        icon: "controls",
        text: "Customization Options: Choose between white cards or printed cards with your custom logo for enhanced branding and visibility."
      },
      {
        icon: "public",
        text: "Global Frequency Compatibility: Developed with independent intellectual property, these tags function seamlessly within the global frequency range."
      }
    ]
  },
  {
    title: "Boom Barrier",
    img: boom,
    description: "Manage vehicular access effectively with our robust and versatile Boom Barrier, ideal for industrial, commercial, and residential applications.",
    features: [
      {
        icon: "extension",
        text: "Extended Reach: Supports boom lengths up to 8 meters, covering wider entry points."
      },
      {
        icon: "shield",
        text: "Durable Design: Choose between IP54 or optional IP65 weatherproof ratings to suit your environment."
      },
      {
        icon: "card",
        text: "CE Certified: Ensures compliance with safety and quality standards."
      },
      {
        icon: "speed",
        text: "Speedy Operation: Adjustable boom opening time from 2 to 5 seconds, facilitating rapid traffic flow."
      },
      {
        icon: "safety",
        text: "Enhanced Safety: Equipped with built-in infrared sensors, photoelectric sensors, and loop detectors to prevent accidental closure on vehicles."
      },
      {
        icon: "lock",
        text: "Reverse Motor Protection: Deters unauthorized entry attempts."
      },
      {
        icon: "connectivity",
        text: "Flexible Connectivity: Connect seamlessly to access control devices, RFID systems, sensors, and traffic lights through RJ45 (TCP/IP or UDP), RS232, RS485, or relay interfaces."
      }
    ]
  },
  {
    title: "VTS Device",
    img: vts,
    description: "Introducing the VTS Device, your one-stop solution for real-time tracking and monitoring of assets and individuals. This versatile device combines advanced GNSS capabilities, reliable connectivity, and robust features to deliver unparalleled tracking performance.",
    features: [
      {
        icon: "location",
        text: "Universal GNSS Compatibility: Track your assets and people accurately with global coverage from IRNSS/NavIC, GPS, Galileo, GLONASS, and BeiDou."
      },
      {
        icon: "connectivity",
        text: "Reliable Connectivity: Dual SIM slots with 4G and fallback to 3G/2G networks ensure uninterrupted data transmission."
      },
      {
        icon: "tracking",
        text: "Web & Mobile Tracking: Monitor your assets and people on the go with user-friendly web and mobile tracking applications."
      },
      {
        icon: "shield",
        text: "Rugged Design: Dust and water resistant with an IP65 rating, the device withstands harsh environments."
      },
      {
        icon: "battery",
        text: "Long Battery Life: Enjoy extended tracking periods with up to 6-12 hours of battery backup."
      },
      {
        icon: "upgrade",
        text: "Over-the-Air Updates: Configure and update the device remotely for effortless maintenance and improved functionality."
      },
      {
        icon: "fence",
        text: "Built-in Surge Protection: Safeguard the device from electrical surges for reliable operation."
      },
      {
        icon: "alert",
        text: "Tamper Detection: Receive alerts in case of unauthorized tampering attempts."
      }
    ]
  },
  {
    title: "Industrial Switch",
    img: sw1,
    description: "Take control of your industrial network with our feature-packed L2/L3 Managed Industrial Switch. Designed for demanding environments, it delivers robust connectivity, advanced management, and flexible deployment options.",
    features: [
      {
        icon: "hub", // Assuming a router icon represents L2/L3 management
        text: "L2/L3 Management: Gain granular control over your network with advanced Layer 2 and Layer 3 routing features."
      },
      {
        icon: "connectivity",
        text: "High-Performance Connectivity: Gigabit Ethernet ports and SFP slots ensure fast and reliable data transfer for your connected devices."
      },
      {
        icon: "bolt",
        text: "PoE / PoE+ / PoE++ Support: Power compatible IP cameras, access points, and other devices directly through the switch, eliminating the need for separate power supplies."
      },
      {
        icon: "encryption",
        text: "Secure Management: Manage your switch conveniently and securely through a web-based interface or command-line interface (CLI)."
      },
      {
        icon: "shield",
        text: "Rugged Design: Built to withstand harsh industrial environments with IP30 rating and DIN Rail or rack mounting options."
      }
    ]
  },
  {
    title: "IoT Controller",
    img: iot,
    description: "Take command of your diverse IoT ecosystem with the powerful and versatile IoT Controller. Designed for seamless integration with various devices and sensors, it empowers you with centralized control, automation, and data-driven insights.",
    features: [
      {
        icon: "devices",
        text: "Extensive Device Support: Manage a wide range of devices including RFID readers, boom barriers, motors, traffic lights, audio alarms, LED displays, proximity sensors, and various detection sensors (infrared, ultrasonic, photosensor, magnetic loop, radar)."
      },
      {
        icon: "connectivity",
        text: "Robust Connectivity: Connect to your devices through diverse interfaces like RJ45 (TCP/IP or UDP), RS232, RS485, USB, and relays, ensuring flexibility and adaptability."
      },
      {
        icon: "shield",
        text: "Durable Design: With an IP65 rating, the controller withstands dust, water, and harsh environments, making it ideal for outdoor and industrial applications."
      },
      {
        icon: "code",
        text: "Powerful Application Software: Develop custom applications and integrate with existing systems effortlessly using the provided SDK."
      }
    ]
  },
  {
    title: "Positioning/Centering System",
    img: centering,
    description: "Achieve accurate object positioning and centering in diverse applications with our versatile Positioning/Centering System. Featuring multiple sensor options and advanced communication capabilities, it delivers precise measurements and seamless integration into your existing infrastructure.",
    features: [
      {
        icon: "sensors",
        text: "Flexible Sensor Technology: Choose between Infrared, Ultrasonic, Photosensor, Magnetic Loop, or Radar-based sensors to suit your specific detection needs and environmental conditions."
      },
      {
        icon: "configuration",
        text: "Configurable Detection Range: Tailor the system to your requirements with a configurable detection range of up to 25 meters."
      },
      {
        icon: "connectivity",
        text: "Robust Connectivity: Connect to your network and systems through various interfaces like RJ45 (TCP/IP or UDP), RS232, RS485, USB, and relays, ensuring smooth data exchange."
      },
      {
        icon: "shield",
        text: "Durable Design: With an IP65 rating, the system withstands dust, water, and harsh environments, making it ideal for industrial and outdoor applications."
      },
      {
        icon: "code",
        text: "Powerful Application Software: Develop custom applications and integrate with existing systems effortlessly using the provided SDK."
      }
    ]
  },
  {
    title: "LED Signal Light",
    img: signal,
    description: "Ensure clear visual and audible alerts in any environment with our robust LED Signal Light. Featuring customizable options, durable construction, and powerful functionality, it effectively commands attention and enhances safety in diverse applications.",
    features: [
      {
        icon: "lightbulb",
        text: "Bright LED Technology: The long-lasting, energy-efficient LED light ensures excellent visibility even in bright sunlight or challenging weather conditions."
      },
      {
        icon: "palette",
        text: "Eye-Catching Colors: Choose between red, green, or amber to communicate specific messages and comply with regulatory requirements."
      },
      {
        icon: "light",
        text: "Optional Blinker: Enhance visibility further with a programmable blinking mode for even stronger attention-grabbing effect."
      },
      {
        icon: "shield",
        text: "Vandal-Proof Metal Housing: The rugged metal housing safeguards the light against tampering, vandalism, and harsh environments."
      },
      {
        icon: "alert",
        text: "Optional Audio Alarm: Equip your light with an adjustable-level buzzer for additional auditory alerts, ideal for noisy environments."
      },
      {
        icon: "apps",
        text: "Wide Range of Applications: Enhance traffic control, access control, safety warnings, construction site notifications, and much more."
      }
    ]
  },
  {
    title: "Handheld RFID Reader/Writer",
    img: handrfid,
    description: "Empower your mobile data collection with the versatile Handheld RFID Reader/Writer. This powerful device combines advanced wireless connectivity, high-definition display, long battery life, and flexible functionalities to seamlessly capture and manage RFID data in diverse settings.",
    features: [
      {
        icon: "connectivity",
        text: "Seamless Connectivity: Stay connected virtually anywhere with integrated GPRS (4G, 3G/2G), Wi-Fi, and Bluetooth 5.0 capabilities."
      },
      {
        icon: "rfid",
        text: "Global RFID Support: Operate within the 865-867 MHz frequency range, compatible with various RFID tags."
      },
      {
        icon: "touch",
        text: "Intuitive Interface: Navigate efficiently with the high-definition multi-touch capacitive panel for enhanced user experience."
      },
      {
        icon: "battery",
        text: "Uninterrupted Operations: Enjoy over 12 hours of continuous use with the built-in rechargeable battery, ideal for extended deployments."
      },
      {
        icon: "storage",
        text: "Ample Storage: Store critical data with the built-in 32GB memory, expandable up to 128GB using an SD card."
      },
      {
        icon: "controls",
        text: "Convenient Controls: Manage operations effortlessly with dedicated USB, power, scan, and volume keys."
      },
      {
        icon: "error",
        text: "Polarization-Free Reading: Capture tags efficiently with the built-in antenna, eliminating polarization concerns."
      }
    ]
  },
  {
    title: "Rack",
    img: rack,
    description: "From compact 1U to expansive 33U options, discover the perfect server rack to suit your needs and style. Our racks offer customizable colors, secure features, and flexible mounting options, ensuring an ideal fit for your data center or IT infrastructure.",
    features: [
      {
        icon: "size",
        text: "Size Variety: Choose from a wide range of sizes, from 1U to 33U, to accommodate your growing equipment needs."
      },
      {
        icon: "palette",
        text: "Color Customization: Select from classic black or grey, or even personalize with your desired color for a unique look."
      },
      {
        icon: "encryption",
        text: "Enhanced Security: Secure your equipment with key locks and sturdy hinges, deterring unauthorized access."
      },
      {
        icon: "mount",
        text: "Multiple Mounting Options: Choose from wall-mounted, floor-standing, fixed, or portable configurations to match your space and requirements."
      },
      {
        icon: "cooling",
        text: "Optional Cooling: Ensure optimal equipment temperature with an optional ventilation system or fan tray."
      },
      {
        icon: "shield",
        text: "Environmental Protection: Shield your equipment from dust, water, and even rodents with optional protective mesh."
      },
      {
        icon: "compatibility",
        text: "Standardized Compatibility: Our racks conform to DIN 41494 or equivalent standard slots, guaranteeing compatibility with your existing equipment."
      }
    ]
  },

];
