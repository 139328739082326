import { Grid, Typography,Box,Divider } from '@mui/material';
import React from 'react';
import Navbar from './NavBar';
import Footer from './Footer';

const PrivacyPolicy = () => {
    return (
        <>
        <Navbar />
        
        <Divider/>
        <Grid container spacing={2} sx={{padding:'20px'}}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: "36px" }}>Privacy Policy</Typography>
            </Grid>
            <Box sx={{ paddingLeft: '150px',paddingRight: '150px' }}>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: "18px" }}>
                        This Privacy Policy outlines how we collect, use, and safeguard the personal information of visitors to our website. By accessing or using our website, you agree to the terms of this Privacy Policy.
                    </Typography><br /><br />
                    <Typography sx={{ fontSize: "24px" }}>
                        Collection of Personal Information
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "18px" }}>
                        We may collect personal information such as your name and email address when you contact us through our website. We may also collect non-personal information such as your browser type, operating system, and the pages you visit on our website.
                    </Typography><br /><br />
                    <Typography sx={{ fontSize: "24px" }}>
                        Use of Personal Information
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "18px" }}>
                        We use personal information to respond to your inquiries and provide you with the information or services you have requested. We may also use your personal information to improve our website and to customize your experience on our website.
                    </Typography>
                    <br /><br />
                    <Typography sx={{ fontSize: "24px" }}>
                        Disclosure of Personal Information
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "18px" }}>
                        We will not sell, rent, or trade your personal information to third parties. However, we may disclose your personal information if required to do so by law or in the good-faith belief that such action is necessary to comply with legal obligations or protect our rights, property, or safety.
                    </Typography><br /><br />
                    <Typography sx={{ fontSize: "24px" }}>
                        Security of Personal Information
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "18px" }}>
                        We take reasonable precautios to protect the personal information we collect from loss, misuse, unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is completely secure and we cannot guarantee the absolute security of your personal information.
                    </Typography><br /><br />
                    <Typography sx={{ fontSize: "24px" }}>
                        Links to Other Websites
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "18px" }}>
                        Our website may contain links to third-party websites that are not owned or controlled by us. We are not responsible for the privacy practices of these websites and encourage you to review the privacy policies of those websites before providing them with any personal information.
                    </Typography><br /><br />
                    <Typography sx={{ fontSize: "24px" }}>
                        Changes to this Privacy Policy
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "18px" }}>
                        We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes to this Privacy Policy will be posted on our website with the updated effective date.
                    </Typography><br /><br />
                    <Typography sx={{ fontSize: "24px" }}>
                        Contact Us
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "18px" }}>
                        If you have any questions or concerns about this Privacy Policy, please contact us at info@dadhwal.in .
                        <br /><br />
                    </Typography>
                </Grid>
            </Box>
        </Grid>
        <Footer />
        </>

    );

}
export default PrivacyPolicy;