import { Box, Divider } from '@mui/material';
import React from 'react';
import Navbar from '../Components/NavBar';
import Nav2 from '../Components/Nav2';
import ProductCard from '../Components/ProductPage/ProductCard';
import { cardContent } from '../Components/ProductPage/cardData';
import Footer from '../Components/Footer';


function ProductPage() {
  return (    
    <div className="App">
      <Navbar />
      <Box sx={{ flexGrow: 1 }}>
                <Nav2 text="Our Products" />
                {cardContent.map((cardData, index) => {
                    return <ProductCard index={index} cardData={cardData} />
                })}     
        </Box >
      <Box sx={{ height: 120 }} />
    <Footer />
    </div>
  );
}

export default ProductPage;
