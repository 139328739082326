import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import SecurityIcon from '@mui/icons-material/Security';
import BuildIcon from '@mui/icons-material/Build';
import CodeIcon from '@mui/icons-material/Code';
import WifiIcon from '@mui/icons-material/SignalWifi4BarLock';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import PaletteIcon from '@mui/icons-material/Palette';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import SettingsIcon from '@mui/icons-material/Settings';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContactlessIcon from '@mui/icons-material/Contactless';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import StorageIcon from '@mui/icons-material/Storage';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import AppsIcon from '@mui/icons-material/Apps';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import FlareIcon from '@mui/icons-material/Flare';
import SensorsIcon from '@mui/icons-material/Sensors';
import HubIcon from '@mui/icons-material/Hub';
import BoltIcon from '@mui/icons-material/Bolt';
import LockResetIcon from '@mui/icons-material/LockReset';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ExtensionIcon from '@mui/icons-material/Extension';
import PublicIcon from '@mui/icons-material/Public';
import CheckIcon from '@mui/icons-material/Check';
import InsightsIcon from '@mui/icons-material/Insights';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import FenceIcon from '@mui/icons-material/Fence';

export default function renderIcon(iconName) {
    switch (iconName) {
        case 'shield':
            return <SecurityIcon />;
        case 'thermometer':
            return <DeviceThermostatIcon />;
        case 'signal':
            return <WifiIcon />;
        case 'mount':
            return <BuildIcon />;
        case 'integration':
            return <CodeIcon />;
        case 'connectivity':
            return <DeviceHubIcon />;
        case 'configuration':
            return <SettingsEthernetIcon />;
        case 'code':
            return <CodeIcon />;
        case 'id-card':
            return <ContactlessIcon />;
        case 'palette':
            return <PaletteIcon />;
        case 'rfid':
            return <SettingsInputAntennaIcon />;
        case 'lightbulb':
            return <FormatColorFillIcon />;
        case 'location':
            return <GpsFixedIcon />;
        case 'devices':
            return <DevicesOtherIcon />;
        case 'controls':
            return <SettingsIcon />;
        case 'security':
            return <OutdoorGrillIcon />;
        case 'installation':
            return <ExpandMoreIcon />;
        case 'speed':
            return <AccessAlarmIcon />;
        case 'safety':
            return <LockIcon />;
        case 'environment':
            return <InvertColorsIcon />;
        case 'connectivity':
            return <CloudQueueIcon />;
        case 'compatibility':
            return <CheckCircleOutlineIcon />;
        case 'wifi':
            return <WifiIcon />;
        case 'size':
            return <AspectRatioIcon />;
        case 'encryption':
            return <EnhancedEncryptionIcon />;
        case 'cooling':
            return <AcUnitIcon />;
        case 'error':
            return <NearbyErrorIcon />;
        case 'storage':
            return <StorageIcon />;
        case 'battery':
            return <BatteryFullIcon />;
        case 'touch':
            return <TouchAppIcon />;
        case 'apps':
            return <AppsIcon />;
        case 'alert':
            return <AddAlertIcon />;
        case 'light':
            return <FlareIcon />;
        case 'sensors':
            return <SensorsIcon />;
        case 'hub':
            return <HubIcon />;
        case 'bolt':
            return <BoltIcon />;
        case 'lock':
            return <LockResetIcon />;
        case 'card':
            return <CardMembershipIcon />;
        case 'extension':
            return <ExtensionIcon />;
        case 'public':
            return <PublicIcon />;
        case 'tracking':
            return <InsightsIcon />;
        case 'upgrade':
            return <UpgradeIcon />;
        case 'fence':
            return <FenceIcon />;
        default:
            return <CheckIcon />;
    }
}

