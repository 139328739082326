import { Grid, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AutoText } from "./Automation";
import CarosolView from "./CarosolView";
import { CCTVText } from "./CCTV";
import { GNSSText } from "./GNSS";
import { PeriText } from "./Perimeter";
import { ProxiText } from "./Proximity";
import { WBText } from "./WB";
import { WirelessText } from "./Wireless";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: '20px'
    },
    card2: {
        padding: 16,
        margin: 16,
        "@media (min-width: 600px)": {
            marginRight: 16,
            marginBottom: 0,
        },
        backgroundColor: "#abb8c3",
    },
    card1: {
        "@media (min-width: 600px)": {
            flexGrow: 1,
        },
    },
});

const CardComponent = ({ items }) => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8.5}>
                    <Card className={classes.card1}>
                        <CarosolView items={items} />
                        {(location.pathname === '/weighbridge') && <WBText />}
                        {(location.pathname === '/Automation') && <AutoText />}
                        {(location.pathname === '/Proximity') && <ProxiText />}
                        {(location.pathname === '/Perimeter') && <PeriText />}
                        {(location.pathname === '/GNSS') && <GNSSText />}
                        {(location.pathname === '/CCTV') && <CCTVText />}
                        {(location.pathname === '/Wireless') && <WirelessText />}
                    </Card>
                </Grid>
                <Grid item xs={12} md={3.5}>
                    <Card className={classes.card2}>
                        <CardContent>
                            <Typography variant='h5'>
                                SOLUTIONS
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/automation')}>
                                Automation System in Mines
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/weighbridge')}>
                            Weighbridge Installation and Software
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/Wireless')}>
                                Networking using Fiber / RF
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/CCTV')}>
                                CCTV Surveillance
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/Proximity')}>
                                Proximity Warning Device
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/Perimeter')}>
                                Perimeter Surveillance
                            </Typography>
                            <br />
                            <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/GNSS')}>
                                GNSS based Fleet Management System
                            </Typography>
                            <br />

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default CardComponent;