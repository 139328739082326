import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Carousel from 'react-material-ui-carousel';
import BarWithIcons from "../Components/SlideBar";
import Radar from '../resources/Images/radar-1024x427.jpg'
import auto from '../resources/Images/automation1-1024x427.jpg'
import wb from '../resources/Images/weighbridge-1024x427.jpg'
import peri from '../resources/Images/perimeter-surveillance-1024x427.jpg'
import Footer from "../Components/Footer";
import Navbar from "../Components/NavBar";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        [theme.breakpoints.up('xs')]: {
            height: '180px'
        },
        [theme.breakpoints.up('sm')]: {
            height: '250px'
        },
        [theme.breakpoints.up('md')]: {
            height: '400px'
        },
        [theme.breakpoints.up('lg')]: {
            height: '600px',
        },
        [theme.breakpoints.up('xl')]: {
            height: '800px'
        },
    },
}));
const items = [
    {
        title: '',
        description: '',
        imgUrl: Radar,
    },
    {
        title: '',
        description: '',
        imgUrl: wb,
    },
    {
        title: '',
        description: '',
        imgUrl: auto,
    },
    {
        title: '',
        description: '',
        imgUrl: peri,
    },
];



const HomePage = () => {
    const classes = useStyles();

    return (
        <>
            <Navbar />
            <Divider />
            <Box sx={{ flexGrow: 1, height: '100%' }}>
                <Carousel
                    autoPlay={true}
                    animation="slide"
                    navButtonsProps={{
                        style: {
                            // backgroundColor: 'transparent',
                            color: '#FFFFFF',
                            boxShadow: 'none',
                        },
                    }}
                    navButtonsWrapperProps={{
                        style: {
                            bottom: '10px',
                        },
                    }}
                >
                    {items.map((item, index) => (
                        <img src={item.imgUrl} alt='Images' className={classes.root} style={{
                            width: '100%',
                        }} />
                    ))}
                </Carousel>
            </Box >
            <BarWithIcons />
            <Box sx={{ height: 20 }} />
            <Footer />
        </>
    );
};

export default HomePage;