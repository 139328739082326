import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Slide, Typography } from '@mui/material';

import automation from '../resources/Images/safe-automation-150x150.png'
import cctv from '../resources/Images/safe-cctv-150x150.png'
import dsrc from '../resources/Images/safe-dsrc-150x150.png'
import gps from '../resources/Images/safe-gps-150x150.png'
import perimeter from '../resources/Images/safe-perimeter-150x150.png'
import radar from '../resources/Images/safe-radar-150x150.png'
import software from '../resources/Images/safe-software-150x150.png'
import wb from '../resources/Images/safe-wb-150x150.png'
import wifi from '../resources/Images/safe-wifi-150x150.png'
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        backgroundColor: '#b30000',
        padding: '10px',
        marginTop: '10px'
    },
    companyIcon: {
        height: '60px',
        margin: '10px',
    },
}));

const companies = [
    { id: 1, name: "AUTOMATION", icon: automation, link: '/Automation' },
    { id: 2, name: "CCTV SURVEILLANCE", icon: cctv, link: '/CCTV' },
    { id: 3, name: "DSRC", icon: dsrc, link: '/Automation' },
    { id: 4, name: "GPS", icon: gps, link: '/GNSS' },
    { id: 5, name: "PERIMETER SURVEILLANCE", icon: perimeter, link: '/Perimeter' },
    { id: 6, name: "RADAR", icon: radar, link: '/Proximity' },
    { id: 7, name: "ONLINE SOFTWARE", icon: software, link: '/Automation' },
    { id: 8, name: "WEIGHBRIDGE", icon: wb, link: '/weighbridge' },
    { id: 9, name: "WIRELESS NETWORKING", icon: wifi, link: '/Wireless' },
];

const BarWithIcons = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [showIcons, setShowIcons] = React.useState(false);
    const [isMobile, setIsMobile] = useState()
    const [currentSet, setCurrentSet] = React.useState(0);

    useEffect(() => {
        setShowIcons(true);
    }, []);

    useEffect(() => {
        if (showIcons) {
            const interval = setInterval(() => {
                setCurrentSet((prevSet) => (prevSet + 1) % Math.ceil(companies.length / isMobile));
            }, 2000); // show each set of icons for 5 seconds

            return () => {
                clearInterval(interval);
            };
        }
    }, [showIcons]);

    const visibleCompanies = companies.slice(
        currentSet * isMobile,
        currentSet * isMobile + isMobile
    );


    const handleResize = () => {
        if (window.innerWidth < 500) {
            setIsMobile(2)
        }
        else if (window.innerWidth < 720) {
            setIsMobile(3)
        } else if (window.innerWidth < 1000) {
            setIsMobile(4)
        }
        else {
            setIsMobile(5)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    });

    useEffect(() => {
        handleResize();
    }, []);

    return (
        <Box className={classes.root}>
            {showIcons && (
                <Slide in={true} direction="left" timeout={1000}>
                    <Grid container justifyContent="center" alignItems="center" display={'flex'}>
                        {visibleCompanies.map((company) => (
                            <Grid item key={company.id} sx={{ textAlign: 'center', flexGrow: 1 }}>
                                <img
                                    src={company.icon}
                                    alt={company.name}
                                    className={classes.companyIcon}
                                    onClick={() => navigate(company.link)}
                                />
                                <Typography sx={{ color: 'white' }}>{company.name}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Slide>
            )}
        </Box>
    );
};

export default BarWithIcons;
